import React, { FC, Fragment, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import SimpleBar from 'simplebar-react';

import { BuilderService, ProductService } from '@core/services';
import { AppActionCreators } from '@redux/app';
import { InventoryActionCreators } from '@redux/inventory';

import { Chevron } from '@components/icons';
import { InventoryCard } from '@components/products';
import { Modal } from '@components/shared';
import { Translate } from '@components/translations';
import { ProductHelper } from '@core/helpers';
import { ga } from '@core/helpers/ga';
import { CustomIntroID } from '@core/introJs';
import { AppActionTypes } from '@core/redux/app/actions';
import { Api, Builder, States } from '@core/types';
import { BaseProduct, Type } from '@core/types/products';
import useIntroJs from '@hooks/useIntroJs';
import useIsMasterOrigin from '@hooks/useIsMasterOrigin';
import usePackoutServer from '@hooks/usePackoutServer';

interface IProps {
  app?: States.AppState;
  inventory?: States.InventoryState;
  products?: States.ProductState;
  removeProduct: Function;
  setInventory: Function;
  submit: (request: Api.ISubmitInventoryRequest) => void;
}

const InventoryNavigation: FC<IProps> = ({ app, products, inventory, removeProduct, setInventory, submit }) => {
  const dispatch = useDispatch();
  const [showNoProductsError, setShowNoProductsError] = useState(Boolean);
  const { packoutServer } = usePackoutServer();
  const { index } = useIntroJs();
  const isMasterOrigin = useIsMasterOrigin();
  const exportView = !packoutServer || isMasterOrigin;

  if (!products || !inventory || app === undefined || products.isLoading || app.site === undefined) {
    return null;
  }

  const showFooter = app.site.submissionPageUrl && app.site.submissionPageUrl.length > 0;

  const handleSubmit = () => {
    if (!app.site) {
      return;
    }

    if (inventory.layers.length === 0) {
      setShowNoProductsError(true);
      return;
    }

    // hide inventory when submitting so that the submitting message appears (mainly an issue on mobile)
    setInventory(false);

    // Fire GA event
    ga('packoutstack', 'entry');

    setTimeout(() => {
      if (app === undefined || app.site === undefined || !app.site.submissionPageUrl) {
        return null;
      }

      submit({
        cultureCode: app.site.cultureCode,
        redirectUrl: app.site.submissionPageUrl,
        inventoryData: JSON.stringify({ layers: inventory.layers, inventoryOnly: inventory.inventoryOnlyProducts }),
      });
    }, 1000);
  };

  const renderProducts = () => {
    const inventoryOnly = inventory.inventoryOnlyProducts.slice().reverse();
    const allLayers = ProductService.getAllLayers(inventory.layers);
    const layers = inventory.layers.slice().reverse();
    const stackType = ProductService.getStackType(layers, products.categories);

    const onlyHasBase = Object.keys(allLayers).every(layerId => {
      const cast = Number(layerId) as Builder.LayerPosition;
      const layer = allLayers[cast];

      if (cast === Builder.LayerPosition.front) {
        // Check if we have the base //
        return layer.length === 1;
      }

      // Filter out inventory only //
      const productsOnLayer = ProductService.getByLayers(layer, products.categories).filter(x => !x.isInventoryOnly);

      return productsOnLayer.length === 0;
    });

    if (stackType === Builder.StackType.default && layers.length === 0) {
      return (
        <div className="inv-nav__empty">
          <Translate resourceString="packout.noproducts" />
        </div>
      );
    }

    const renderInventoryProduct = (product: BaseProduct) => {
      return (
        // <CSSTransition key={index} timeout={200} classNames="slide-in-right">
        <InventoryCard
          model={product}
          disabled={false}
          onClick={() => {
            // Gross function signature I'm sorry but it's only in this one use case //
            removeProduct(undefined, 0, undefined, undefined, true, packoutServer?.settings ? packoutServer?.settings.preserveSession : false);
            ga('packoutstack', 'product-removed', { articleNumber: product.articleNumber });
            if (packoutServer) {
              packoutServer.emit('product-removed', product.articleNumber);
            }
          }}
        />
      );
    };

    const render = (x: Builder.BaseLayer, index: number, position: Builder.LayerPosition, lockBaseLayer?: boolean) => {
      const scopedLayer = BuilderService.getLayersByType(layers, position);

      switch (x.type) {
        case Builder.LayerType.full: {
          const layer = x as Builder.FullLayer;
          const product = ProductService.getByAgilityId(layer.agilityId, products.categories);
          const canRemove = index === 0 && !lockBaseLayer;
          // Get the actual index as we looping backwards
          const actualIndex = scopedLayer.length - 1 - index;

          if (product === undefined) {
            return null;
          }

          return (
            // <CSSTransition key={index} timeout={200} classNames="slide-in-right">
            <>
              <InventoryCard
                key={index}
                model={product}
                disabled={!canRemove}
                onClick={() => {
                  removeProduct(
                    actualIndex,
                    0,
                    Builder.RemoveType.full,
                    position,
                    undefined,
                    packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                  );
                  ga('packoutstack', 'product-removed', { articleNumber: product.articleNumber });
                  if (packoutServer) {
                    packoutServer.emit('product-removed', product.articleNumber);
                  }
                }}
              />
            </>
            // </CSSTransition> */}
          );
        }

        case Builder.LayerType.half: {
          const layer = x as Builder.HalfLayer;
          const leftProducts = layer.leftAgilityIds
            .slice()
            .reverse()
            .map(z => ProductService.getByAgilityId(z, products.categories));

          const rightProducts = layer.rightAgilityIds
            .slice()
            .reverse()
            .map(z => ProductService.getByAgilityId(z, products.categories));

          const canRemove = index === 0;
          // Get the actual index as we looping backwards
          const actualIndex = scopedLayer.length - 1 - index;

          return (
            <Fragment key={index}>
              {leftProducts.map((leftProduct, leftIndex) => {
                if (leftProduct === undefined) {
                  return null;
                }

                const actualLeftIndex = leftProducts.length - 1 - leftIndex;

                return (
                  <>
                    <InventoryCard
                      key={`${index}:${leftProduct.agilityId}:${leftIndex}`}
                      model={leftProduct}
                      onClick={() =>
                        removeProduct(
                          actualIndex,
                          actualLeftIndex,
                          Builder.RemoveType.left,
                          position,
                          undefined,
                          packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                        )
                      }
                      disabled={!canRemove}
                    />
                  </>
                );
              })}
              {rightProducts.map((rightProduct, rightIndex) => {
                if (rightProduct === undefined) {
                  return null;
                }

                const actualRightIndex = rightProducts.length - 1 - rightIndex;

                return (
                  <>
                    <InventoryCard
                      key={`${index}:${rightProduct.agilityId}:${rightIndex}`}
                      model={rightProduct}
                      onClick={() =>
                        removeProduct(
                          actualIndex,
                          actualRightIndex,
                          Builder.RemoveType.right,
                          position,
                          undefined,
                          packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                        )
                      }
                      disabled={!canRemove}
                    />
                  </>
                );
              })}
            </Fragment>
          );
        }

        default: {
          return null;
        }
      }
    };

    return (
      <SimpleBar style={{ maxHeight: '100%', overflowX: 'hidden' }}>
        {Object.keys(allLayers).map(position => {
          const castPosition = Number(position) as Builder.LayerPosition;
          const layer = allLayers[castPosition];
          const productsOnLayer = ProductService.getByLayers(layer, products.categories);
          const doubleBaseOnThisLayer = stackType === Builder.StackType.double && productsOnLayer.some(x => x.productType === Type.base);
          const layeredBaseOnThisLayer = stackType === Builder.StackType.layered && productsOnLayer.some(x => x.productType === Type.base);
          const hasOnlyFrontLayers = ProductService.hasOnlyFrontLayers(inventory.layers, products.categories);

          let label = '';
          switch (castPosition) {
            default:
            case Builder.LayerPosition.front:
              label = layeredBaseOnThisLayer ? 'packout.bottom-layer' : 'packout.front-layer';
              break;
            case Builder.LayerPosition.back:
              label = 'packout.back-layer';
              break;
            case Builder.LayerPosition.top:
              label = 'packout.top-layer';
              break;
          }

          if (layer.length === 0) {
            return <></>;
          }

          if (doubleBaseOnThisLayer) {
            return (
              <>
                <div className="inv-nav__label">
                  <Translate resourceString="packout.base-layer" />
                </div>
                {render(layer[0], 0, Builder.LayerPosition.front, layer.length > 1)}
                {layer.length > 1 && (
                  <>
                    <br />
                    <div className="inv-nav__label">
                      <Translate resourceString={label} />
                    </div>
                    {layer
                      .slice(1)
                      .reverse()
                      .map((x, index) => render(x, index, castPosition))}
                  </>
                )}
                <br />
              </>
            );
          }

          if (layeredBaseOnThisLayer) {
            return (
              <>
                <div className="inv-nav__label">
                  <Translate resourceString="packout.base-layer" />
                </div>
                {render(layer[0], 0, Builder.LayerPosition.front, !onlyHasBase)}
                {layer.length > 1 && (
                  <>
                    <br />
                    <div className="inv-nav__label">
                      <Translate resourceString={label} />
                    </div>
                    {layer
                      .slice(1)
                      .reverse()
                      .map((x, index) => render(x, index, castPosition))}
                  </>
                )}
                <br />
              </>
            );
          }

          return (
            <>
              {!hasOnlyFrontLayers && (
                <div className="inv-nav__label">
                  <Translate resourceString={label} />
                </div>
              )}
              {layer.reverse().map((x, index) => render(x, index, castPosition))}
              <br />
            </>
          );
        })}
        {inventoryOnly.length > 0 && (
          <>
            <br />
            <div className="inv-nav__label">
              <Translate resourceString="packout.inventory-only" />
            </div>
            {inventoryOnly.map(x => renderInventoryProduct(x))}
          </>
        )}
      </SimpleBar>
    );
  };

  const handleSetInventory = () => {
    if (!exportView) {
      return;
    }

    dispatch<AppActionTypes>({ type: 'APP/SET_INVENTORY', payload: !app.inventoryOpen });
  };

  const productCount = ProductService.getByLayers(inventory.layers, products.categories).length;

  return (
    <Fragment>
      <nav
        data-id={CustomIntroID.Four}
        data-tablet-id={CustomIntroID.FourTablet}
        data-mobile-id={CustomIntroID.FourMobile}
        className={`inv-nav ${productCount === 0 && index !== CustomIntroID.FourMobile ? 'inv-nav--hidden' : ''} ${
          app.inventoryOpen ? 'active' : ''
        } ${packoutServer ? 'inv-nav--transparent' : ''}`}
      >
        <div className={`${packoutServer !== null ? 'until-tablet' : ''}`}>
          <div className={`inv-nav__title ${!exportView ? 'inv-nav__title--grid' : ''}`} onClick={() => handleSetInventory()}>
            <h2>
              <Translate resourceString="packout.yoursystem" />
            </h2>
            {exportView ? (
              <div className="inv-nav__icon">
                <Chevron />
              </div>
            ) : (
              <button
                type="button"
                className="btn btn--black btn--inline btn--small"
                onClick={() => {
                  if (packoutServer.settings.skipSummaryModal) {
                    ProductHelper.collectArticleNumbers(packoutServer, products, inventory, articleNumbers => {
                      packoutServer.emit('submit-basket', articleNumbers);
                    });
                  } else {
                    dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                  }
                }}
              >
                <Translate resourceString="packout.add-to-basket" />
              </button>
            )}
          </div>
          <div
            className={`inv-nav__title inv-nav__title--mobile ${packoutServer ? 'inv-nav__title--grid' : ''}`}
            onClick={() => handleSetInventory()}
          >
            <h2>
              <Translate resourceString="packout" /> {`(${productCount})`}
            </h2>

            {exportView ? (
              <div className="inv-nav__icon">
                <Chevron />
              </div>
            ) : (
              <button
                type="button"
                className="btn btn--black btn--inline btn--small"
                onClick={() => {
                  if (packoutServer.settings.skipSummaryModal) {
                    ProductHelper.collectArticleNumbers(packoutServer, products, inventory, articleNumbers => {
                      packoutServer.emit('submit-basket', articleNumbers);
                    });
                  } else {
                    dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                  }
                }}
              >
                <Translate resourceString="packout.add-to-basket" />
              </button>
            )}
          </div>
          <div className={`inv-nav__items ${showFooter ? '' : 'inv-nav__items--full'}`}>{renderProducts()}</div>
        </div>

        {showFooter && (
          <div className={`inv-nav__footer ${!exportView ? 'inv-nav__footer--small' : ''}`} data-id={CustomIntroID.Five}>
            {exportView ? (
              <>
                <h2>
                  <Translate resourceString="packout.dreampackout" />
                </h2>
                <button type="button" className="btn btn--black btn--inline btn--small" onClick={() => handleSubmit()}>
                  <Translate resourceString="SubmitEntry" />
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn--black btn--inline btn--small"
                onClick={() => {
                  if (packoutServer.settings.skipSummaryModal) {
                    ProductHelper.collectArticleNumbers(packoutServer, products, inventory, articleNumbers => {
                      packoutServer.emit('submit-basket', articleNumbers);
                    });
                  } else {
                    dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: true });
                  }
                }}
              >
                <Translate resourceString="packout.add-to-basket" />
              </button>
            )}
          </div>
        )}
      </nav>
      <Modal visible={showNoProductsError} onClose={() => setShowNoProductsError(false)}>
        <div className="intro intro--500">
          <div className="intro__inner intro__inner--center">
            <h3>
              <Translate resourceString="packout.submit.error.title" />
            </h3>
            <p>
              <Translate resourceString="packout.submit.error.description" />
            </p>
            <button className="btn btn--inline btn--small" onClick={() => setShowNoProductsError(false)}>
              <Translate resourceString="close" />
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  inventory: state.inventory,
  products: state.products,
  app: state.app,
});

const mapDispatchToProps = {
  removeProduct: (
    layerIndex: number,
    productIndex: number,
    removeType: Builder.RemoveType,
    position: Builder.LayerPosition,
    isInventoryOnly?: boolean,
    preserveSession?: boolean | undefined,
  ) => InventoryActionCreators.removeProduct(layerIndex, productIndex, removeType, position, isInventoryOnly, preserveSession),
  setInventory: (open: boolean) => AppActionCreators.setInventory(open),
  submit: (request: Api.ISubmitInventoryRequest) => InventoryActionCreators.submit(request),
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryNavigation);
