import { Eye, Minus, Plus, QuestionMark } from '@components/icons';
import { Translate } from '@components/translations';
import { CustomIntroID } from '@core/introJs';
import { ProductService } from '@core/services';
import { Builder, States } from '@core/types';
import { BaseType, Type } from '@core/types/products';
import usePackoutServer from '@hooks/usePackoutServer';
import { AppActionCreators } from '@redux/app';
import { InventoryActionCreators } from '@redux/inventory';
import React, { FC } from 'react';
import { connect } from 'react-redux';

interface IProps {
  clearProducts: Function;
  setIntro: Function;
  zoomOut: Function;
  zoomIn: Function;
  toggleFrontStack: Function;
  inventory: States.InventoryState;
  products?: States.ProductState;
  app?: States.AppState;
}

const Header: FC<IProps> = ({ clearProducts, setIntro, zoomOut, zoomIn, inventory, products, toggleFrontStack, app }) => {
  const { packoutServer } = usePackoutServer();

  const hasDoubleBase = React.useMemo(() => {
    if (products && inventory.layers.length > 0) {
      const layer = ProductService.getByFullLayer(inventory.layers[0] as Builder.FullLayer, products.categories);
      if (layer) {
        if (layer.productType === Type.base && layer.productBaseType === BaseType.double) {
          return true;
        }
      }
    }

    return false;
  }, [products, JSON.stringify(inventory.layers)]);

  return (
    <div className="builder__buttons">
      <div
        className="builder__buttons-inner"
        data-id={CustomIntroID.Three}
        data-tablet-id={CustomIntroID.ThreeTablet}
        data-mobile-id={CustomIntroID.ThreeMobile}
      >
        {hasDoubleBase && (
          <div className="builder__button builder__button--with-text" onClick={() => toggleFrontStack()}>
            <Eye />
            <Translate resourceString={app?.hideFrontStack ? 'packout.show-front-stack' : 'packout.hide-front-stack'} />
          </div>
        )}
        <div
          className="builder__button builder__button--with-text"
          onClick={() => clearProducts(packoutServer?.settings ? packoutServer.settings.preserveSession : false)}
        >
          <Translate resourceString="restart" />
        </div>
        <div className="builder__button" onClick={() => setIntro(true)}>
          <QuestionMark />
        </div>
        <div className="builder__button" onClick={() => zoomIn()}>
          <Plus />
        </div>
        <div className="builder__button" onClick={() => zoomOut()}>
          <Minus />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: States.RootState) => ({
  app: state.app,
  inventory: state.inventory,
  products: state.products,
});

const mapDispatchToProps = {
  clearProducts: (preserveSession?: boolean | undefined) => InventoryActionCreators.clearProducts(preserveSession),
  setIntro: (open: boolean) => AppActionCreators.setIntro(open),
  zoomOut: () => AppActionCreators.zoomOut(),
  zoomIn: () => AppActionCreators.zoomIn(),
  toggleFrontStack: () => AppActionCreators.toggleFrontStack(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
