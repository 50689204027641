import { Cross } from '@components/icons';
import { InventoryCard } from '@components/products';
import { Translate } from '@components/translations';
import { ProductHelper } from '@core/helpers';
import { ga } from '@core/helpers/ga';
import { AppActionTypes } from '@core/redux/app/actions';
import { InventoryActionCreators } from '@core/redux/inventory';
import { BuilderService, ProductService } from '@core/services';
import { Builder } from '@core/types';
import { BaseProduct, Type } from '@core/types/products';
import { RootState } from '@core/types/states';
import usePackoutServer from '@hooks/usePackoutServer';
import React, { FC, Fragment } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';

interface IProps {
  removeProduct: Function;
}

const Summary: FC<IProps> = ({ removeProduct }) => {
  const { products, inventory, app } = useSelector((x: RootState) => x);
  const dispatch = useDispatch();
  const { packoutServer } = usePackoutServer();

  const submitPackoutServer = () => {
    ProductHelper.collectArticleNumbers(packoutServer, products, inventory, articleNumbers => {
      dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false });
      if (packoutServer) {
        packoutServer.emit('submit-basket', articleNumbers);
      }
      ga('packoutstack', 'entry');
    });
  };

  const renderProducts = () => {
    const inventoryOnly = inventory.inventoryOnlyProducts.slice().reverse();
    const allLayers = ProductService.getAllLayers(inventory.layers);
    const layers = inventory.layers.slice().reverse();
    const stackType = ProductService.getStackType(layers, products.categories);

    const onlyHasBase = Object.keys(allLayers).every(layerId => {
      const cast = Number(layerId) as Builder.LayerPosition;
      const layer = allLayers[cast];

      if (cast === Builder.LayerPosition.front) {
        // Check if we have the base //
        return layer.length === 1;
      }

      // Filter out inventory only //
      const productsOnLayer = ProductService.getByLayers(layer, products.categories).filter(x => !x.isInventoryOnly);

      return productsOnLayer.length === 0;
    });

    if (stackType === Builder.StackType.default && layers.length === 0) {
      return (
        <div className="inv-nav__empty">
          <Translate resourceString="packout.noproducts" />
        </div>
      );
    }

    const renderInventoryProduct = (product: BaseProduct) => {
      return (
        // <CSSTransition key={index} timeout={200} classNames="slide-in-right">
        <InventoryCard
          model={product}
          disabled={false}
          onClick={() => {
            // Gross function signature I'm sorry but it's only in this one use case //
            removeProduct(
              0,
              0,
              Builder.RemoveType.full,
              Builder.LayerPosition.front,
              true,
              packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
            );
            ga('packoutstack', 'product-removed', { articleNumber: product.articleNumber });
            if (packoutServer) {
              packoutServer.emit('product-removed', product.articleNumber);
            }
          }}
        />
      );
    };

    const render = (x: Builder.BaseLayer, index: number, position: Builder.LayerPosition, lockBaseLayer?: boolean) => {
      const scopedLayer = BuilderService.getLayersByType(layers, position);

      switch (x.type) {
        case Builder.LayerType.full: {
          const layer = x as Builder.FullLayer;
          const product = ProductService.getByAgilityId(layer.agilityId, products.categories);
          const canRemove = index === 0 && !lockBaseLayer;
          // Get the actual index as we looping backwards
          const actualIndex = scopedLayer.length - 1 - index;

          if (product === undefined) {
            return null;
          }

          return (
            // <CSSTransition key={index} timeout={200} classNames="slide-in-right">
            <>
              <InventoryCard
                key={index}
                model={product}
                disabled={!canRemove}
                onClick={() => {
                  removeProduct(
                    actualIndex,
                    0,
                    Builder.RemoveType.full,
                    position,
                    undefined,
                    packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                  );
                  ga('packoutstack', 'product-removed', { articleNumber: product.articleNumber });
                  if (packoutServer) {
                    packoutServer.emit('product-removed', product.articleNumber);
                  }
                }}
              />
            </>
            // </CSSTransition> */}
          );
        }

        case Builder.LayerType.half: {
          const layer = x as Builder.HalfLayer;
          const leftProducts = layer.leftAgilityIds
            .slice()
            .reverse()
            .map(z => ProductService.getByAgilityId(z, products.categories));

          const rightProducts = layer.rightAgilityIds
            .slice()
            .reverse()
            .map(z => ProductService.getByAgilityId(z, products.categories));

          const canRemove = index === 0;
          // Get the actual index as we looping backwards
          const actualIndex = scopedLayer.length - 1 - index;

          return (
            <Fragment key={index}>
              {leftProducts.map((leftProduct, leftIndex) => {
                if (leftProduct === undefined) {
                  return null;
                }

                const actualLeftIndex = leftProducts.length - 1 - leftIndex;

                return (
                  <>
                    <InventoryCard
                      key={`${index}:${leftProduct.agilityId}:${leftIndex}`}
                      model={leftProduct}
                      onClick={() =>
                        removeProduct(
                          actualIndex,
                          actualLeftIndex,
                          Builder.RemoveType.left,
                          position,
                          undefined,
                          packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                        )
                      }
                      disabled={!canRemove}
                    />
                  </>
                );
              })}
              {rightProducts.map((rightProduct, rightIndex) => {
                if (rightProduct === undefined) {
                  return null;
                }

                const actualRightIndex = rightProducts.length - 1 - rightIndex;

                return (
                  <>
                    <InventoryCard
                      key={`${index}:${rightProduct.agilityId}:${rightIndex}`}
                      model={rightProduct}
                      onClick={() =>
                        removeProduct(
                          actualIndex,
                          actualRightIndex,
                          Builder.RemoveType.right,
                          position,
                          undefined,
                          packoutServer?.settings ? packoutServer?.settings.preserveSession : false,
                        )
                      }
                      disabled={!canRemove}
                    />
                  </>
                );
              })}
            </Fragment>
          );
        }

        default: {
          return null;
        }
      }
    };

    return (
      <SimpleBar style={{ maxHeight: '100%', overflowX: 'hidden' }}>
        {Object.keys(allLayers).map(position => {
          const castPosition = Number(position) as Builder.LayerPosition;
          const layer = allLayers[castPosition];
          const productsOnLayer = ProductService.getByLayers(layer, products.categories);
          const doubleBaseOnThisLayer = stackType === Builder.StackType.double && productsOnLayer.some(x => x.productType === Type.base);
          const layeredBaseOnThisLayer = stackType === Builder.StackType.layered && productsOnLayer.some(x => x.productType === Type.base);
          const hasOnlyFrontLayers = ProductService.hasOnlyFrontLayers(inventory.layers, products.categories);

          let label = '';
          switch (castPosition) {
            default:
            case Builder.LayerPosition.front:
              label = layeredBaseOnThisLayer ? 'packout.bottom-layer' : 'packout.front-layer';
              break;
            case Builder.LayerPosition.back:
              label = 'packout.back-layer';
              break;
            case Builder.LayerPosition.top:
              label = 'packout.top-layer';
              break;
          }

          if (layer.length === 0) {
            return <></>;
          }

          if (doubleBaseOnThisLayer) {
            return (
              <>
                <div className="inv-nav__label">
                  <Translate resourceString="packout.base-layer" />
                </div>
                {render(layer[0], 0, Builder.LayerPosition.front, layer.length > 1)}
                {layer.length > 1 && (
                  <>
                    <br />
                    <div className="inv-nav__label">
                      <Translate resourceString={label} />
                    </div>
                    {layer
                      .slice(1)
                      .reverse()
                      .map((x, index) => render(x, index, castPosition))}
                  </>
                )}
                <br />
              </>
            );
          }

          if (layeredBaseOnThisLayer) {
            return (
              <>
                <div className="inv-nav__label">
                  <Translate resourceString="packout.base-layer" />
                </div>
                {render(layer[0], 0, Builder.LayerPosition.front, !onlyHasBase)}
                {layer.length > 1 && (
                  <>
                    <br />
                    <div className="inv-nav__label">
                      <Translate resourceString={label} />
                    </div>
                    {layer
                      .slice(1)
                      .reverse()
                      .map((x, index) => render(x, index, castPosition))}
                  </>
                )}
                <br />
              </>
            );
          }

          return (
            <>
              {!hasOnlyFrontLayers && (
                <div className="inv-nav__label">
                  <Translate resourceString={label} />
                </div>
              )}
              {layer.reverse().map((x, index) => render(x, index, castPosition))}
              <br />
            </>
          );
        })}
        {inventoryOnly.length > 0 && (
          <>
            <br />
            <div className="inv-nav__label">
              <Translate resourceString="packout.inventory-only" />
            </div>
            {inventoryOnly.map(x => renderInventoryProduct(x))}
          </>
        )}
      </SimpleBar>
    );
  };

  return (
    <div className={`summary ${app.summaryOpen ? 'active' : ''}`}>
      <div className="summary__inner">
        <div className="summary__close" onClick={() => dispatch<AppActionTypes>({ type: 'APP/SET_SUMMARY', payload: false })}>
          <Cross colour="#000" />
        </div>
        <div className="summary__title">
          <h2>
            <Translate resourceString="packout.yoursystem" />
          </h2>
        </div>
        <div className="summary__products">{renderProducts()}</div>
        <div className="summary__footer">
          <button type="button" className="btn btn--black btn--inline btn--small" onClick={() => submitPackoutServer()}>
            <Translate resourceString="packout.add-to-basket" />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  removeProduct: (
    layerIndex: number,
    productIndex: number,
    removeType: Builder.RemoveType,
    position: Builder.LayerPosition,
    isInventoryOnly?: boolean,
    preserveSession?: boolean | undefined,
  ) => InventoryActionCreators.removeProduct(layerIndex, productIndex, removeType, position, isInventoryOnly, preserveSession),
};

export default connect(undefined, mapDispatchToProps)(Summary);
